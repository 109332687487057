// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-contact-en-js": () => import("./../src/templates/contact.en.js" /* webpackChunkName: "component---src-templates-contact-en-js" */),
  "component---src-templates-page-index-fr-js": () => import("./../src/templates/page/index.fr.js" /* webpackChunkName: "component---src-templates-page-index-fr-js" */),
  "component---src-templates-page-index-en-js": () => import("./../src/templates/page/index.en.js" /* webpackChunkName: "component---src-templates-page-index-en-js" */),
  "component---src-templates-index-fr-js": () => import("./../src/templates/index.fr.js" /* webpackChunkName: "component---src-templates-index-fr-js" */),
  "component---src-templates-contact-fr-js": () => import("./../src/templates/contact.fr.js" /* webpackChunkName: "component---src-templates-contact-fr-js" */),
  "component---src-templates-index-en-js": () => import("./../src/templates/index.en.js" /* webpackChunkName: "component---src-templates-index-en-js" */),
  "component---src-templates-events-fr-js": () => import("./../src/templates/events.fr.js" /* webpackChunkName: "component---src-templates-events-fr-js" */),
  "component---src-templates-article-index-fr-js": () => import("./../src/templates/article/index.fr.js" /* webpackChunkName: "component---src-templates-article-index-fr-js" */),
  "component---src-templates-article-index-en-js": () => import("./../src/templates/article/index.en.js" /* webpackChunkName: "component---src-templates-article-index-en-js" */),
  "component---src-templates-events-en-js": () => import("./../src/templates/events.en.js" /* webpackChunkName: "component---src-templates-events-en-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

